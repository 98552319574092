import React from "react";

const BlogHero = () => {
  return (
    <div className="bloghero-container">
      <div className="bloghero-container-wrapper">
        <h1>En samling av våre beste råd og erfaringer</h1>
      </div>
    </div>
  );
};

export default BlogHero;

export const CVGENERATORDATA = 'CVGENERATORDATA'
export const CVNEWGENERATORDATA = 'CVNEWGENERATORDATA'
export const ADDEDUCATION = 'ADDEDUCATION'
export const REMOVEEDUCATION = 'REMOVEEDUCATION'
export const EDITEDUCATION = 'EDITEDUCATION'
// NEW EDUCATION TYPES ADDED
export const ADDNEWEDUCATION = 'ADDNEWEDUCATION'
export const REMOVENEWEDUCATION = 'REMOVENEWEDUCATION'
export const EDITNEWEDUCATION = 'EDITNEWEDUCATION'
export const UPDATENEWEDUCATIONTOGGLE = 'UPDATENEWEDUCATIONTOGGLE'
// TILL HERE
export const ADDWORKEXPERIANCE = 'ADDWORKEXPERIANCE'
export const EDITWORKEXPERIANCE = 'EDITWORKEXPERIANCE'
export const REMOVEWORKEXPERIANCE = 'REMOVEWORKEXPERIANCE'
// NEW EXPERIENCE TYPES ADDED
export const ADDNEWWORKEXPERIANCE = 'ADDNEWWORKEXPERIANCE'
export const EDITNEWWORKEXPERIANCE = 'EDITNEWWORKEXPERIANCE'
export const REMOVENEWWORKEXPERIANCE = 'REMOVENEWWORKEXPERIANCE'
export const UPDATENEWEXPERIENCETOGGLE = 'UPDATENEWEXPERIENCETOGGLE'
// 
export const ADDSLIDER = 'ADDSLIDER'
export const EDITSLIDER = 'EDITSLIDER'
export const DELETESLIDER = 'DELETESLIDER'
export const ADDLANGUAGE = 'ADDLANGUAGE'
export const EDITLANGUAGE = 'EDITLANGUAGE'
export const DELETELANGUAGE = 'DELETELANGUAGE'
// NEW LANGUAGE TYPES ADDED
export const ADDNEWLANGUAGE = 'ADDNEWLANGUAGE'
export const EDITNEWLANGUAGE = 'EDITNEWLANGUAGE'
export const DELETENEWLANGUAGE = 'DELETENEWLANGUAGE'
//
export const ADDPROPERTY = 'ADDPROPERTY'
export const EDITPROPERTY = 'EDITPROPERTY'
// NEW PROPERTY TYPES ADDED
export const ADDNEWPROPERTY = 'ADDNEWPROPERTY'
export const EDITNEWPROPERTY = 'EDITNEWPROPERTY'
//
export const ADDCOURSE = 'ADDCOURSE'
export const EDITCOURSE = 'EDITCOURSE'
// NEW CCOURSE TYPES ADDED
export const ADDNEWCOURSE = 'ADDNEWCOURSE'
export const EDITNEWCOURSE = 'EDITNEWCOURSE'
export const SAVEDATA = 'SAVEDATA'
export const NEWSAVEDATA = 'NEWSAVEDATA'
export const ADDREFERENCE = 'ADDREFERENCE'
export const EDITREFERENCE = 'EDITREFERENCE'
export const REFTOGGLE = 'REFTOGGLE'
// NEW REFERENCE TYPES ADDED
export const ADDNEWREFERENCE = 'ADDNEWREFERENCE'
export const EDITNEWREFERENCE = 'EDITNEWREFERENCE'
export const REFNEWTOGGLE = 'REFNEWTOGGLE'
//
export const EDITCONFIG = 'EDITCONFIG'
export const EDITADDITIONALACCORDIAN = 'EDITADDITIONALACCORDIAN'
export const EDITNEWADDITIONALACCORDIAN = 'EDITNEWADDITIONALACCORDIAN'
export const EDITHOBBIES = 'EDITHOBBIES'
export const DELETEHOBBY = 'DELETEHOBBY'
export const ADDHOBBY = 'ADDHOBBY'
// NEW HOBBIES TYPES ADDED
export const EDITNEWHOBBIES = 'EDITNEWHOBBIES'
export const DELETENEWHOBBY = 'DELETENEWHOBBY'
export const ADDNEWHOBBY = 'ADDNEWHOBBY'
//
export const EDITINTERNSHIPS = 'EDITINTERNSHIPS'
export const DELETEINTERNSHIP = 'DELETEINTERNSHIP'
export const ADDINTERNSHIP = 'ADDINTERNSHIP'
// NEW INTERNSHIP TYPES ADDED
export const EDITNEWINTERNSHIPS = 'EDITNEWINTERNSHIPS'
export const DELETENEWINTERNSHIP = 'DELETENEWINTERNSHIP'
export const ADDNEWINTERNSHIP = 'ADDNEWINTERNSHIP'
export const UPDATENEWINTERNSHIPTOGGLE = 'UPDATENEWINTERNSHIPTOGGLE'
//
export const DATEPRESENT = 'DATEPRESENT'
export const UPDATEEDUCATIONTOGGLE = 'UPDATEEDUCATIONTOGGLE'
export const UPDATEEXPERIENCETOGGLE = 'UPDATEEXPERIENCETOGGLE'
export const UPDATEINTERNSHIPTOGGLE = 'UPDATEINTERNSHIPTOGGLE'


import React from "react";

const GdprHero = () => {
  return (
    <div className="aboutus-hero-container">
      <div className="aboutus-hero-container-wrapper">
        <h1>GDPR</h1>
      </div>
    </div>
  );
};

export default GdprHero;
